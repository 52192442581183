
import Vue from "vue";
import { mapGetters } from "vuex";
import RangeInput from "@/components/Partials/RangeInput.vue";
import Api from "@/services/Api";
import moment from "moment";

export default Vue.extend({
  name: "CreateMentoringProgram",
  components: {
    RangeInput
  },
  props: {
    isPublished: Boolean,
    isCreated: Boolean,
    create: Boolean,
    errors: Object,
    mentee: Object,
    mentor: Object,
    program: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: this.program as any,
      menteeEmail: "",
      durationOptions: ["weeks", "days", "hours"],
      certificateOptions: [
        { id: 1, name: "Yes" },
        { id: 0, name: "No" }
      ],
      OpenIndicator: {
        render: (
          createElement: (arg0: string, arg1: { attrs: { src: any } }) => any
        ) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      maxTitleChars: 200,
      suggestedUsers: [],
      openSuggestionList: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  async created() {
    this.setData();
    if (this.$route.query.MpRqiD) {
      try {
        const {
          data: { data }
        } = await Api().get(`/program/request/${this.$route.query.MpRqiD}`);
        this.data.mentee_id = data.mentee_id;
        this.data.price = data.price;
        this.data.start_date = moment(data.date).format("DD-MM-YYYY");
        this.data.request_id = data.id;
        this.$emit("mentee", data.mentee);
      } catch (errors) {
        console.error(errors);
      }
    }
  },
  methods: {
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    setData() {
      if (this.isPublished || this.isCreated) {
        this.data = { ...this.program };
      }
    },
    selectDate(date: string) {
      this.data.start_date = date
        .split("-")
        .reverse()
        .join("-");
    },
    async getMenteeFromEmail() {
      try {
        const users = await this.$store.dispatch(
          "program/GET_USER_FROM_EMAIL",
          this.menteeEmail
        );
        this.suggestedUsers = users;
      } catch (e) {
        (this as any).$alertify.error("Mentee not found");
        console.log(e);
      }
    },
    selectUser(user: any) {
      this.data.mentee_id = user.id;
      this.openSuggestionList = false;
      this.suggestedUsers = [];
      this.$emit("mentee", user);
    },
    handleInputBlur() {
      if (this.menteeEmail === "") {
        this.openSuggestionList = false;
        return;
      }
      setTimeout(() => {
        if (!this.$el.contains(document.activeElement)) {
          this.openSuggestionList = false;
        }
      }, 400);
    },
    onDiscard() {
      // if (!this.isPublished) {
      //   this.$router.push(`/profile`);
      // } else {
      //   this.$router.push(`/mentorcasts/${this.$route.params.id}`);
      // }
    },
    onNext() {
      this.$emit("update", this.data);
    }
  }
});
