
import Vue from "vue";
import CreateMentoringProgram from "@/components/MentoringPlan/CreateMentoringProgram.vue";
import ReviewMentoringProgram from "@/components/MentoringPlan/ReviewMentoringProgram.vue";
import moment from "moment-timezone";

export default Vue.extend({
  name: "MentoringProgramFormContainer",
  components: {
    CreateMentoringProgram,
    ReviewMentoringProgram
  },
  props: {
    isPublished: Boolean,
    create: Boolean,
    profile: Object
  },
  data() {
    return {
      url: "",
      data: {
        mentee_id: "",
        title: "",
        start_date: "",
        price: 0,
        no_of_milestones: 2,
        is_certificate: 1,
        is_free_library: 1,
        duration: "",
        duration_type: "days"
      } as any,
      errors: {} as any,
      mentor: {},
      mentee: {},
      readyToPublish: false,
      isCreated: false,
      isAlreadyAgreed: this.isPublished,
      maxSeats: "",
      loading: false
    };
  },
  created() {
    this.mentor = this.profile;
    if (this.isPublished) {
      this.loading = true;
      this.$store
        .dispatch("program/GET_PROGRAM", this.$route.params.id)
        .then(data => {
          console.log(data);
          this.readyToPublish = true;
          this.loading = false;
          // if (!data.can_edit) {
          //   this.$router.push(`/mentorcasts/${data.id}`);
          // }

          for (const e of Object.keys(this.data)) {
            if (e in data) {
              this.data[e] = data[e];
            }
          }

          this.mentee = data.mentee;

          this.data.start_date = moment(this.data.start_date)
            .format("DD-MM-YYYY")
            .toString();
        });
    }
  },
  methods: {
    resolveDynamic(path: any, obj: any) {
      return path.split(".").reduce(function(prev: any, curr: any) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    update(data: any) {
      //@ts-ignore
      this.validate(data).then(() => {
        this.data = { ...data };
        this.readyToPublish = true;
      });
    },
    edit() {
      this.isAlreadyAgreed = false;
      this.readyToPublish = false;
      this.isCreated = true;
      //@ts-ignore
      if (this.$refs["programForm"])
        //@ts-ignore
        this.$refs["programForm"].setData();
    },
    validate(data: any) {
      this.errors = {};
      let store = this.$store.dispatch("program/CREATE_PROGRAM", {
        ...data,
        validation: true
      });
      if (this.isPublished) {
        store = this.$store.dispatch("program/UPDATE_PROGRAM", {
          id: this.$route.params.id,
          formData: { ...data, validation: true }
        });
      }
      return new Promise(resolve => {
        store
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      });
    },
    setErrors(errors: any, isCategoryError = false) {
      if (isCategoryError) {
        this.errors.categories = errors.categories;
      } else {
        this.errors = errors;
      }
      this.readyToPublish = false;
      this.isCreated = true;
    },
    setMentee(mentee: any) {
      this.mentee = mentee;
    }
  }
});
